import React from 'react'
import styled from 'react-emotion'
import Footer from '../components/footer'
import Navigation from '../components/navigation'
import Layout from '../layouts'

const Main = styled('div')({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

const SignupWrapper = styled('div')({
  margin: '0 auto',
  marginTop: '40px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '700px',
  minHeight: '400px',
  justifyContent: 'center',
  marginBottom: 'auto',
  '@media only screen and (max-width: 560px)': {
    margin: '0 20px 20px 20px',
  },
})

const Text = styled('div')({
  fontSize: '22px',
  color: '#808080',
  lineHeight: '26px',
  marginBottom: '40px',
  textAlign: 'center',
})

const Heading = styled('h1')({
  fontSize: '42px',
  lineHeight: '40px',
  textAlign: 'center',
  color: '#000000',
  fontWeight: '800',
  marginBottom: '40px',
  marginTop: '10px',
  '@media only screen and (max-width: 700px)': {
    fontSize: '34px',
    lineHeight: '30px',
    marginBottom: '20px',
  },
})

const FormWrapper = styled('div')({
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '520px',
  width: '100%',
  borderRadius: 8,
  backgroundColor: '#43CCAD',
  padding: '3px',
})

const FormHeading = styled('span')({
  fontSize: '16px',
  color: '#FFF',
  fontWeight: '600',
  margin: '10px 20px',
})

const Form = styled('form')({
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '520px',
  width: '100%',
  borderRadius: 6,
  backgroundColor: '#FFF',
})

const FormButton = styled('button')({
  border: 0,
  backgroundColor: '#0765D2',
  color: '#FFF',
  width: 'fit-content',
  padding: '10px 20px',
  borderRadius: '4px',
  marginLeft: 'auto',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  ':focus': {
    outline: 0,
  },
  ':hover': {
    backgroundColor: '#145FB5',
    transition: 'all 0.2s ease',
  },
})

const FormRow = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '13px',
  fontWeight: 600,
  borderBottom: '1px solid #E5E8EF',
  width: '100%',
  padding: '12px 20px',
  input: {
    margin: '0 -10px',
    padding: '10px',
    borderRadius: '4px',
    border: 0,
    fontSize: '16px',
    fontWeight: 400,
  },
  'input:focus': {
    outline: 0,
    backgroundColor: '#F6F6F6',
  },
  ':last-child': {
    borderBottom: 0,
  },
})

const ButtonRow = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '13px',
  fontWeight: 600,
  width: '100%',
  padding: '10px',
})

const SecondPage = () => (
  <Layout>
    <Main>
      <Navigation />
      <SignupWrapper>
        <Heading>
          You’re one step closer to being more productive{' '}
          <span role="img" aria-label="Person walking">
            🚶
          </span>
        </Heading>
        <Text>We'll let you know when you can get access!</Text>
        <FormWrapper>
          <FormHeading>Signing up to early access</FormHeading>
          <Form
            name="signup"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="signup" />
            <FormRow>
              Full Name
              <input
                type="text"
                name="fullname"
                placeholder="e.g. Keanu Reeves"
                required
              />
            </FormRow>
            <FormRow>
              Email Address
              <input
                type="email"
                name="email"
                placeholder="e.g. your@email.com"
                required
              />
            </FormRow>
            <ButtonRow>
              <FormButton type="submit">Request Early Access</FormButton>
            </ButtonRow>
          </Form>
        </FormWrapper>
      </SignupWrapper>
      <Footer />
    </Main>
  </Layout>
)

export default SecondPage
